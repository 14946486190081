import React, { useState, useCallback } from 'react';
import {
	AppLayout,
	ContentLayout,
	Header,
	Link,
	SideNavigation,
} from '@cloudscape-design/components';
import ConversationHistory from './ConversationHistory';

const MainLayout = ( { children, accessToken }) => {

	const [navigationOpen, setNavigationOpen] = useState(true);

	const toggleNavigation = () => {
		setNavigationOpen(!navigationOpen);
	};

	const [updateTrigger, setUpdateTrigger] = useState(false);

	const triggerUpdate = useCallback(() => {
		console.log('Triggering update');
		setUpdateTrigger(true);
	}, []);

	const resetTrigger = useCallback(() => {
		console.log('Resetting trigger');
		setUpdateTrigger(false);
	}, []);

	console.log('MainLayout render, updateTrigger:', updateTrigger);

	return (
		<AppLayout

			navigationOpen={navigationOpen}
			onNavigationChange={toggleNavigation}
			navigation={
				<SideNavigation
					header={{
						href: '/',
						text: 'Friday',
					}}
					items={[
						{ type: "link", text: "Chat", href: "/Chat" },
						{ type: "link", text: "Kendra", href: "/Kendra" }
					]}

				>
				</SideNavigation>
			}
			content={
				<ContentLayout
					// header={
					// 	<Header variant="h1" info={<Link variant="info">Info</Link>}>
					// 		Friday
					// 	</Header>
					// }
				>
					{typeof children === 'function'
						? children({ updateTrigger, triggerUpdate, resetTrigger, accessToken })
						: children}
				</ContentLayout>
			}
			splitPanel={
				<ConversationHistory triggerUpdate={triggerUpdate} accessToken={accessToken} />
			}
		>
		</AppLayout>
		
	);
};

export default MainLayout;