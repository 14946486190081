import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
	Container,
	Header,
	SpaceBetween,
	Button,
	Link,
	Spinner,
	PromptInput,
} from '@cloudscape-design/components';
import Markdown from 'react-markdown'
import { Input } from 'antd';
import { useLocation } from "react-router-dom";
import config from '../config';
import EventDispatcher from './eventDispatcher';
import { LoadingBar, Avatar } from "@cloudscape-design/chat-components";
import KendraOnboarding from './KendraOnboarding';

const { TextArea } = Input;

const Kendra = ({ updateTrigger, resetTrigger, accessToken }) => {
	// State to store the current message being typed by the user
	const [message, setMessage] = useState('');
	const location = useLocation();
	const path = location.pathname.replace(/\//g, '');
	const lastMessageRef = useRef(null);

	const alias = accessToken.alias;
	const jwt_token = accessToken.jwtToken
	const givenName = accessToken.given_name;
	const backend = config.apiUrl

	const [kendraTitle, setKendraTitle] = useState(() => {
		const storedKendraTitle = sessionStorage.getItem('kendraTitle');
		return storedKendraTitle || "Start a new Conversation";
	});

	const [loadingState, setLoadingState] = useState('');

	const [kendraEndpoint, setKendraEndpoint] = useState(() => {
		const storedKendraEndpoint = sessionStorage.getItem('kendraEndpoint');
		return storedKendraEndpoint || '';
	});

	console.log("kendra endpoint kendra main " + kendraEndpoint)

	// State to store the session_id
	// Initially, it tries to retrieve the session_id from the session storage
	// If there's no session_id stored, it starts with -1
	const [kendraSessionId, setKendraSessionId] = useState(() => {
		const storedKendraSessionId = sessionStorage.getItem('kendraSessionId');
		return storedKendraSessionId || "-1";
	});

	// State to store the chat history
	// Initially, it tries to retrieve the chat history from the session storage
	// If there's no chat history stored, it starts with an empty array
	const [kendraHistory, setKendraHistory] = useState(() => {
		const storedKendraHistory = sessionStorage.getItem('kendraHistory');
		return storedKendraHistory ? JSON.parse(storedKendraHistory) : [];
	});

	// Set up an EventSource to receive real-time messages from the server
	useEffect(() => {
		const eventSource = new EventSource(backend + '/kendra');
		eventSource.onmessage = (event) => {
			// When a new message is received from the server,
			// add it to the chat history with the sender as 'Jarvis'
			setKendraHistory((prevHistory) => [
				...prevHistory,
				{ sender: 'Friday', message: event.data },
			]);
		};
		eventSource.onerror = (error) => {
			console.error('EventSource error:', error);
		};
		// Clean up the EventSource on component unmount
		return () => {
			eventSource.close();
		};
	}, []);

	// Reload Component from conversation change
	useEffect(() => {
		if (updateTrigger) {
			console.log('Update triggered in Kendra, retrieving history');
			retrieveHistory();
			// Reset the trigger after handling the update
			resetTrigger();
		}
	}, [updateTrigger]);

	const retrieveHistory = async () => {

		try {

			const storedKendraSessionId = sessionStorage.getItem('kendraSessionId')

			const history = await fetch(backend + '/retrieve_history', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,

				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ sessionId: storedKendraSessionId, alias, path, givenName }),
			});
			console.log("history function executed ")

			if (history.ok) {
				console.log("history function OK ")
				// Add the user's message to the chat history
				const reader_title = history.body.getReader();
				const { value, done } = await reader_title.read()

				var aux = new TextDecoder().decode(value)

				// Update chat history, session id and title
				setKendraHistory(JSON.parse(aux))
				setKendraSessionId(storedKendraSessionId)
				setKendraTitle(sessionStorage.getItem('kendraTitle'))

			} else {
				console.error('Error getting history');
			}
		} catch (error) {
			console.error('Error Retrieving History:', error);
		}
	}

	// Function to handle sending a message to the server
	const handleSendMessage = async (event) => {
		event.preventDefault();
		setLoadingState(true)

		try {

			if (kendraEndpoint === '' && (sessionStorage.getItem('kendraEndpoint') !== '' || sessionStorage.getItem('kendraEndpoint') !== "undefined")) {
				setKendraEndpoint(sessionStorage.getItem('kendraEndpoint'))
			} else {
				console.error('Error retrieving kendra endpoint, complete configuration');
			}

			// Get new title for conversation if it's a new one
			if (kendraTitle === 'Start a new Conversation') {
				const response_title = await fetch(kendraEndpoint + '/title', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${jwt_token}`,

					},
					mode: 'cors',
					credentials: 'include',
					body: JSON.stringify({ message, sessionId: kendraSessionId, alias, path }),
				});

				if (response_title.ok) {
					// Add the user's message to the chat history
					const reader_title = response_title.body.getReader();
					const { value, done } = await reader_title.read()

					var aux = new TextDecoder().decode(value)
					// Remove the initial and last quotes
					aux = aux.replace(/^"(.*)"$/, '$1');

					// Replace \" with regular double quotes
					aux = aux.replace(/\\"/g, '"');

					setKendraTitle(aux)
					sessionStorage.setItem('kendraTitle', aux)

				} else {
					console.error('Error getting title');
				}

			}

			const response = await fetch(kendraEndpoint + '/kendra', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,

				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ message, sessionId: kendraSessionId, alias, path, givenName }),
			});

			if (response.ok) {
				// Add the user's message to the chat history
				setKendraHistory((prevHistory) => [
					...prevHistory,
					{ sender: givenName, message },
				]);
				setMessage(''); // Clear the message input

				// Read the streamed response from the server and update the chat history
				const reader = response.body.getReader();

				let responseText = '';
				let jsonResponse = '';
				while (true) {
					const { value, done } = await reader.read();
					if (done) break;
					const chunk = new TextDecoder().decode(value);

					responseText += chunk;
					jsonResponse = JSON.parse(responseText)

					setKendraHistory((prevHistory) => [
						...prevHistory,
						{ sender: 'Friday', message: jsonResponse.kendra_response },
					]);
					setKendraSessionId(jsonResponse.kendra_session_id)
					sessionStorage.setItem('kendraSessionId', jsonResponse.kendra_session_id)
					EventDispatcher.dispatch('sessionStorageUpdated', { key: 'kendraSessionId', value: jsonResponse.kendra_session_id });
				}
			} else {
				console.error('Error sending message');
			}
		} catch (error) {
			console.error('Error sending message:', error);
		} finally {
			setLoadingState(false)
		}
	};

	const handleKeyDown = useCallback(
		(event) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				handleSendMessage(event);
			}
		},
		[handleSendMessage]
	);

	const handleLoadingState = () => {
		if (loadingState) {
			return (
				<LoadingBar variant="gen-ai-masked" />
			);
		}
	}

	// Function to clear the kendra history
	const clearKendraHistory = async () => {
		setKendraHistory([]); // Clear the kendra history state
		sessionStorage.removeItem('kendraHistory'); // Remove the kendra history from session storage
		setKendraSessionId("-1")
		sessionStorage.setItem('kendraSessionId', "-1")
		setKendraTitle('Start a new Conversation')
		sessionStorage.setItem('kendraTitle', "Start a new Conversation")
	};

	// Reload Component from conversation change
	// useEffect(() => {
	// 	if (kendraConfig === 'inactive') {
	// 		console.log('No kendra config, do configuration');
	// 		retrieveHistory();
	// 		// Reset the trigger after handling the update
	// 		resetTrigger();
	// 	}
	// }, [kendraConfig]);

	// Save the chat history to session storage whenever it changes
	useEffect(() => {
		sessionStorage.setItem('kendraHistory', JSON.stringify(kendraHistory));
	}, [kendraHistory]);

	const replaceEscapeSequences = (text) => {
		try {

			// Extract the answer and source_documents
			let answer = text.answer;
			const sourceDocuments = text.source_documents;

			if ((sourceDocuments === undefined) && (answer === undefined)) {
				answer = text;
				return (
					<>
						<Markdown>{answer}</Markdown>
					</>
				);
			} else {
				return (
					<>
						<Markdown>{answer}</Markdown>
						<div>
							<h3>Source Documents:</h3>
							<ul>
								{sourceDocuments.map((doc, index) => (
									<li key={index}>
										<Link href={doc} target="_blank" rel="noopener noreferrer">
											{doc}
										</Link>
									</li>
								))}
							</ul>
						</div>
					</>
				);
			}
		} catch (error) {
			console.error('Error parsing JSON string:', error);
			// If the text is not a valid JSON string, render it as a string
			return String(text);
		}
	};


	const loadAvatar = (sender) => {
		if (sender === "Friday") {
			return (
				<Avatar
					color="gen-ai"
					iconName="gen-ai"
				/>
			);
		} else {
			return (
				<Avatar
				/>
			);
		}
	};

	useEffect(() => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [kendraHistory, lastMessageRef]);

	return (
		// <Container
		// 	header={
		// 		<Header variant="h2" description="">
		// 			<SpaceBetween direction="horizontal" size="s">
		// 				{kendraTitle}
		// 				<Button onClick={clearKendraHistory}>Clear Chat History</Button>
		// 			</SpaceBetween>
		// 		</Header>
		// 	}
		// >
		<div>

			<KendraOnboarding accessToken={accessToken} />


			<br />
			<div>
				<Header variant="h2" description="">
					<SpaceBetween direction="horizontal" size="s">
						{kendraTitle}
						<Button iconName="refresh" variant="icon" onClick={clearKendraHistory}>Clear Chat History</Button>
					</SpaceBetween>
				</Header>
			</div>
			<br />
			{/* <form onSubmit={updateKendraIndex}>
				<Input
					value={kendraIndex}
					onChange={(event) => setKendraIndex(event.target.value)}
				/>
				<br />
				<br />
				<Button type="submit">Update Index</Button>
				<br />
				<br />
			</form> */}

			<div>
				{kendraHistory.map((chat, index) => (
					<div key={index} ref={index === kendraHistory.length - 1 ? lastMessageRef : null}>
						<SpaceBetween direction="vertical" size="s">
							<Container>
								<SpaceBetween direction="horizontal" size="s">
									{loadAvatar(chat.sender)}
									<Header
										variant="h2"
									>
										{chat.sender}
									</Header>

								</SpaceBetween>
								{replaceEscapeSequences(chat.message)}
								{console.log("rendered")}
							</Container>
						</SpaceBetween>
						<br />
					</div>
				))}
			</div>
			{handleLoadingState()}
			<br />
			<PromptInput onAction={handleSendMessage}
				onChange={({ detail }) => setMessage(detail.value)}
				value={message}
				actionButtonAriaLabel="Send message"
				actionButtonIconName="send"
				ariaLabel="Prompt input with action button"
				placeholder="Ask Friday a question"
				maxRows={50}
			/>
		</div>

	);
}

export default Kendra;




