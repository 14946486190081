import React, { useState, useEffect, useCallback, useRef } from 'react';
import config from '../config';
import {
	Container,
	Header,
	SpaceBetween,
	Button,
	FormField,
	Input,
	Box,
	Modal,
	Form,
	Link,
	Spinner,
	PromptInput,
} from '@cloudscape-design/components';

const KendraOnboarding = ({ accessToken }) => {
	const alias = accessToken.alias
	const jwt_token = accessToken.jwtToken
	const backend = config.apiUrl

	const [kendraConfig, setKendraConfig] = useState(() => {
		const storedKendraConfig = sessionStorage.getItem('kendraConfig');
		return storedKendraConfig || false;
	});

	useEffect(() => {
		// This function will be executed when the component mounts
		console.log('in config pre check')

		console.log("!kendraConfig: " + !kendraConfig)
		if (kendraConfig === "false") {
			console.log('in config')
			getKendraConfig();
		}
	}, []);

	// console.log(kendraConfig)

	const [kendraEndpoint, setKendraEndpoint] = useState(() => {
		const storedKendraEndpoint = sessionStorage.getItem('kendraEndpoint');
		return storedKendraEndpoint || '';
	});

	const [kendraIndex, setKendraIndex] = useState('');

	const [visible, setVisible] = useState(true);
	// console.log(visible)

	const getKendraConfig = async () => {
		try {
			// Send a request to the backend to update the kendra index
			const response = await fetch(backend + '/get_kendra_config', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,

				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ alias }),
			});

			if (response.ok) {
				// Add the user's message to the chat history
				const reader = response.body.getReader();
				const { value, done } = await reader.read()

				var responseText = new TextDecoder().decode(value)
				console.log("responseText: " + responseText)
				// Remove the initial and last quotes
				var jsonResponse = JSON.parse(responseText)

				console.log('kendra status: ' + jsonResponse.config_status)
				setKendraConfig(jsonResponse.config_status)
				sessionStorage.setItem('kendraConfig', jsonResponse.config_status)
				setKendraEndpoint(jsonResponse.kendra_endpoint)
				sessionStorage.setItem('kendraEndpoint', jsonResponse.kendra_endpoint)
				console.log("Set visible 1: " +kendraConfig)
				console.log("Set visible: " +!kendraConfig)
				setVisible(!kendraConfig)
			} else {
				console.error('Error retrieving kendra status');
			}
		} catch (error) {
			console.error('Error retrieving kendra status:', error);
		}
	};

	const sleep = (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	const onboardUser = async () => {
		try {
			// Send a request to the backend to onboard the user

			const response = await fetch(kendraEndpoint + '/onboard_user', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,
				
				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ user: alias }),
			});

			if (response.ok) {
				// console.log('user onboarded successfully');
			} else {
				console.error('Error onboarding user');
			}
		} catch (error) {
			console.error('Error onboarding user:', error);
		}
	};



	useEffect(() => {
		// This function will be executed when the component mounts
		setVisible(!kendraConfig)
	}, [kendraConfig]);

	const updateKendraConfig = async (event) => {
		event.preventDefault();
		try {
			// Send a request to the backend to update the kendra index
			const response = await fetch(backend + '/update_kendra_config', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,

				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ kendraEndpoint, kendraIndex, alias }),
			});

			if (response.ok) {
				setKendraConfig(true)
				sessionStorage.setItem('kendraConfig', 'true')
				setVisible(false)
				setKendraEndpoint(kendraEndpoint)
				sessionStorage.setItem('kendraEndpoint', kendraEndpoint)

				// Update data in Kendra Endpoint
				try {
					onboardUser()
					sleep(5000)
				} catch (error) {
					console.error('Error onboarding kendra user:', error);
				}

				try {
					// Send a request to the backend to update the kendra index
					const response = await fetch(kendraEndpoint + '/update_kendra_config', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${jwt_token}`,

						},
						mode: 'cors',
						credentials: 'include',
						body: JSON.stringify({ kendraEndpoint, kendraIndex, alias }),
					});

					if (response.ok) {
						console.log('Kendra Endpoint updated successfully')
					} else {
						console.error('Error updating kendra Endpoint');
					}
				} catch (error) {
					console.error('Error updating kendra Endpoint:', error);
				}


				console.log('Kendra config updated successfully')
			} else {
				console.error('Error updating kendra config');
			}
		} catch (error) {
			console.error('Error updating kendra indeconfigx:', error);
		}
	};


	return (
		<Modal
			onDismiss={() => setVisible(false)}
			visible={visible}
			footer={
				<Box float="right">
					<SpaceBetween direction="horizontal" size="xs">
						<Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
						<Button variant="primary" onClick={updateKendraConfig}>Submit</Button>
					</SpaceBetween>
				</Box>
			}
			header="Kendra Endpoint Configuration"
		>
			<Form
			// actions={
			// 	<SpaceBetween direction="horizontal" size="xs">
			// 		<Button formAction="none" variant="link">
			// 			Cancel
			// 		</Button>
			// 		<Button variant="primary">Submit</Button>
			// 	</SpaceBetween>
			// }
			// header={<Header variant="h1">Form header</Header>}
			>
				<SpaceBetween direction="vertical" size="l">
					<FormField label="API GW Endpoint">
						<Input
							onChange={({ detail }) => setKendraEndpoint(detail.value)}
							value={kendraEndpoint}
						/>
					</FormField>
					<FormField label="Kendra Index">
						<Input
							onChange={({ detail }) => setKendraIndex(detail.value)}
							value={kendraIndex}
						/>
					</FormField>
				</SpaceBetween>
			</Form>
		</Modal>
	);
}

export default KendraOnboarding;