import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import MainLayout from "./MainLayout";
import Chat from "./Chat";
import Kendra from "./Kendra";
import config from '../config';

const Main = (props) => {

	const accessToken = props.accessToken
	const jwt_token = accessToken.jwtToken
	const alias = props.accessToken.alias
	const backend = config.apiUrl;
	const navigate = useNavigate();

	const location = useLocation();
	const path = location.pathname.replace(/\//g, '');

	useEffect(() => {
		// console.log('onboarding user');
		onboardUser();
		if (path === '') {
			navigate('/Chat');
		}
	}, [])

	// TODO: Add buffering while the tables get created for new user
	const onboardUser = async () => {
		try {
			// Send a request to the backend to onboard the user

			const response = await fetch(backend + '/onboard_user', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,
				
				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ user: alias }),
			});

			if (response.ok) {
				// console.log('user onboarded successfully');
			} else {
				console.error('Error onboarding user');
			}
		} catch (error) {
			console.error('Error onboarding user:', error);
		}
	};


	return (
		<MainLayout accessToken={accessToken}>
			{({ updateTrigger, triggerUpdate, resetTrigger, accessToken }) => (
				<Routes>
					{/* <Route path="/" element={<Chat updateTrigger={updateTrigger} triggerUpdate={triggerUpdate} resetTrigger={resetTrigger} accessToken={accessToken} />} /> */}
					<Route path="/Chat" element={<Chat updateTrigger={updateTrigger} triggerUpdate={triggerUpdate} resetTrigger={resetTrigger} accessToken={accessToken} />} />
					<Route path="/Kendra" element={<Kendra updateTrigger={updateTrigger} triggerUpdate={triggerUpdate} resetTrigger={resetTrigger} accessToken={accessToken} />} />
				</Routes>
			)}
		</MainLayout>
	);
};

export default Main;
