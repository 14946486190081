class EventDispatcher {
	constructor() {
	  // Initialize an empty object to store event listeners
	  this.events = {};
	}
  
	addEventListener(eventName, callback) {
	  // If there are no listeners for the given eventName, create an empty array
	  if (!this.events[eventName]) {
		this.events[eventName] = [];
	  }
	  // Add the callback function to the array of listeners for the given eventName
	  this.events[eventName].push(callback);
	}
  
	removeEventListener(eventName, callback) {
	  // If there are no listeners for the given eventName, return early
	  if (!this.events[eventName]) return;
	  // Filter out the callback function from the array of listeners for the given eventName
	  this.events[eventName] = this.events[eventName].filter(cb => cb !== callback);
	}
  
	dispatch(eventName, detail) {
	  // If there are no listeners for the given eventName, return early
	  if (!this.events[eventName]) return;
	  // Call each callback function in the array of listeners for the given eventName
	  // and pass the `detail` object as the event data
	  this.events[eventName].forEach(callback => callback({ detail }));
	}
  }
  
  // Export a singleton instance of the EventDispatcher class
  export default new EventDispatcher();