import React, { useEffect, useState, useCallback } from 'react';
import {
	SplitPanel,
	Button,
	Cards,
	Box,
	SpaceBetween,
	Header,
	Link,
	Container
} from '@cloudscape-design/components';
import { useLocation } from "react-router-dom";
import config from '../config';
import EventDispatcher from './eventDispatcher';

const ConversationHistory = ({ triggerUpdate, accessToken }) => {

	const jwt_token = accessToken.jwtToken
	const alias = accessToken.alias
	const backend = config.apiUrl
	const [kendraEndpoint, setKendraEndpoint] = useState(() => {
		const storedKendraEndpoint = sessionStorage.getItem('kendraEndpoint');
		return storedKendraEndpoint || '';
	});

	const location = useLocation();
	const path = location.pathname.replace(/\//g, '');

	const [chatConversations, setChatConversations] = useState([]);


	const loadConversation = useCallback((index, title) => () => {
		sessionStorage.setItem(path.toLowerCase() + 'SessionId', index)
		sessionStorage.setItem(path.toLowerCase() + 'Title', title)
		triggerUpdate();
	}, [triggerUpdate]);

	const deleteConversation = useCallback((index) => async () => {
		try {
			const response = await fetch(backend + '/delete_conversation', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,
				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ path, alias, sessionId: index }),
			});
			if (response.ok) {
				sessionStorage.setItem(path.toLowerCase() + 'SessionId', "-1")
				sessionStorage.setItem(path.toLowerCase() + 'Title', "Start a new Conversation")
				triggerUpdate();
				retrieveConversations();
			} else {
				console.error('Error deleting conversation');
			}
		} catch (error) {
			console.error('Error deleting conversation:', error);
		}
	}, [triggerUpdate]);


	const retrieveConversations = useCallback(async () => {
		try {
			let endpoint = ''
			if (path === 'Kendra'){
				endpoint = kendraEndpoint
			} else if (path === 'Chat'){
				endpoint = backend
			}
			const response = await fetch(endpoint + '/retrieve_conversations', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,
				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ alias, path }),
			});
			if (response.ok) {
				const reader = response.body.getReader();
				const { value, done } = await reader.read()
				var aux = JSON.parse(new TextDecoder().decode(value));
				setChatConversations(aux)
				// console.log("chat conversations" + JSON.stringify(aux))
			} else {
				console.error('Error retrieving conversations');
			}
		} catch (error) {
			console.error('Error retrieving conversations:', error);
		}
	}, [alias, location.pathname]);

	useEffect(() => {
		retrieveConversations();
		const handleSessionStorageUpdate = (event) => {
			const { key, value } = event.detail;
			if (key === `${path.toLowerCase()}SessionId`) {
			  retrieveConversations();
			}
		  };
	  
		  EventDispatcher.addEventListener('sessionStorageUpdated', handleSessionStorageUpdate);
	  
		  return () => {
			EventDispatcher.removeEventListener('sessionStorageUpdated', handleSessionStorageUpdate);
		  };
	}, [retrieveConversations, path]);

	return (
		<SplitPanel header="Past Conversations">
			<SpaceBetween size="xxs">
				{chatConversations.map((chat, index) => (
					<div key={index}>
						<Button variant="link" onClick={loadConversation(chat.session_id, chat.conversation_title)}>
							{chat.conversation_title}
						</Button>
						<Button iconName="delete-marker" onClick={deleteConversation(chat.session_id)} />
					</div>
				))}
			</SpaceBetween>
			<br/>
		</SplitPanel>
	);
};

export default ConversationHistory;