import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
	Container,
	Header,
	SpaceBetween,
	Button,
	Spinner,
	PromptInput,
} from '@cloudscape-design/components';
import Markdown from 'react-markdown'
import { Input } from 'antd';
import { useLocation } from "react-router-dom";
// import ApiGatewayRequest from "./ApiGatewayRequest";
import config from '../config';
import EventDispatcher from './eventDispatcher';
import { LoadingBar, Avatar } from "@cloudscape-design/chat-components";


const { TextArea } = Input;


const Chat = ({ updateTrigger, resetTrigger, accessToken }) => {
	// State to store the current message being typed by the user
	const [message, setMessage] = useState('');
	const location = useLocation();
	const path = location.pathname.replace(/\//g, '');
	const lastMessageRef = useRef(null);

	const alias = accessToken.alias;
	const jwt_token = accessToken.jwtToken
	const givenName = accessToken.given_name;
	const backend = config.apiUrl;

	const [chatTitle, setChatTitle] = useState(() => {
		const storedChatTitle = sessionStorage.getItem('chatTitle');
		if (!storedChatTitle) {
			sessionStorage.setItem('chatTitle', 'Start a new Conversation');
		}
		return storedChatTitle || "Start a new Conversation";
	});

	const [loadingState, setLoadingState] = useState('');

	// State to store the session_id
	// Initially, it tries to retrieve the session_id from the session storage
	// If there's no session_id stored, it starts with -1
	const [chatSessionId, setChatSessionId] = useState(() => {
		const storedChatSessionId = sessionStorage.getItem('chatSessionId');
		if (!storedChatSessionId) {
			sessionStorage.setItem('chatSessionId', '-1');
		}
		return storedChatSessionId || "-1";
	});
	// State to store the chat history
	// Initially, it tries to retrieve the chat history from the session storage
	// If there's no chat history stored, it starts with an empty array
	const [chatHistory, setChatHistory] = useState(() => {
		const storedChatHistory = sessionStorage.getItem('chatHistory');
		return storedChatHistory ? JSON.parse(storedChatHistory) : [];
	});

	// Set up an EventSource to receive real-time messages from the server
	useEffect(() => {
		const eventSource = new EventSource(backend + '/chat');
		eventSource.onmessage = (event) => {
			// When a new message is received from the server,
			// add it to the chat history with the sender as 'Friday'
			setChatHistory((prevHistory) => [
				...prevHistory,
				{ sender: 'Friday', message: event.data },
			]);
		};
		eventSource.onerror = (error) => {
			console.error('EventSource error:', error);
		};
		// Clean up the EventSource on component unmount
		return () => {
			eventSource.close();
		};
	}, []);

	// Reload Component from conversation change
	useEffect(() => {
		if (updateTrigger) {
			retrieveHistory();
			// Reset the trigger after handling the update
			resetTrigger();
		}
	}, [updateTrigger]);

	const retrieveHistory = async () => {

		try {

			const storedChatSessionId = sessionStorage.getItem('chatSessionId')

			const history = await fetch(backend + '/retrieve_history', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,

				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ sessionId: storedChatSessionId, alias, path, givenName }),
			});

			if (history.ok) {
				// Add the user's message to the chat history
				const reader_title = history.body.getReader();
				const { value, done } = await reader_title.read()

				var aux = new TextDecoder().decode(value)

				// Update chat history, session id and title
				setChatHistory(JSON.parse(aux))
				setChatSessionId(storedChatSessionId)
				setChatTitle(sessionStorage.getItem('chatTitle'))

			} else {
				console.error('Error getting history');
			}
		} catch (error) {
			console.error('Error Retrieving History:', error);
		}
	}

	// Function to handle sending a message to the server
	const handleSendMessage = async (event) => {
		event.preventDefault();
		setLoadingState(true)

		try {

			// Get new title for conversation if it's a new one
			if (chatTitle === 'Start a new Conversation') {
				const response_title = await fetch(backend + '/title', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${jwt_token}`,

					},
					mode: 'cors',
					credentials: 'include',
					body: JSON.stringify({ message, sessionId: chatSessionId, alias, path }),
				});

				if (response_title.ok) {
					// Add the user's message to the chat history
					const reader_title = response_title.body.getReader();
					const { value, done } = await reader_title.read()

					var aux = new TextDecoder().decode(value)
					// Remove the initial and last quotes
					aux = aux.replace(/^"(.*)"$/, '$1');

					// Replace \" with regular double quotes
					aux = aux.replace(/\\"/g, '"');

					setChatTitle(aux)
					sessionStorage.setItem('chatTitle', aux)

				} else {
					console.error('Error getting title');
				}

			}

			const response = await fetch(backend + '/chat', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${jwt_token}`,

				},
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ message, sessionId: chatSessionId, alias, path, givenName }),
			});

			if (response.ok) {
				// Add the user's message to the chat history
				setChatHistory((prevHistory) => [
					...prevHistory,
					{ sender: givenName, message },
				]);
				setMessage(''); // Clear the message input

				// Read the streamed response from the server and update the chat history
				const reader = response.body.getReader();

				let responseText = '';
				let jsonResponse = '';
				while (true) {
					const { value, done } = await reader.read();
					if (done) break;
					const chunk = new TextDecoder().decode(value);

					responseText += chunk;
					jsonResponse = JSON.parse(responseText)

					setChatHistory((prevHistory) => [
						...prevHistory,
						{ sender: 'Friday', message: jsonResponse.chat_response },
					]);
					console.log("jsonResponse.chat_session_id " + jsonResponse.chat_session_id)
					console.log("JSON.stringify(jsonResponse.chat_session_id) " + JSON.stringify(jsonResponse.chat_session_id))
					setChatSessionId(jsonResponse.chat_session_id)
					sessionStorage.setItem('chatSessionId', jsonResponse.chat_session_id)
					EventDispatcher.dispatch('sessionStorageUpdated', { key: 'chatSessionId', value: jsonResponse.chat_session_id });
				}
			} else {
				console.error('Error sending message');
			}

		} catch (error) {
			console.error('Error sending message:', error);
		} finally {
			setLoadingState(false)
		}
	};

	const handleKeyDown = useCallback(
		(event) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				handleSendMessage(event);
			}
		},
		[handleSendMessage]
	);

	const handleLoadingState = () => {
		if (loadingState) {
			return (
				<LoadingBar variant="gen-ai-masked" />
			);
		}
	}

	// Function to clear the chat history, session id and title
	const clearChatHistory = async () => {
		setChatHistory([]); // Clear the chat history state
		sessionStorage.removeItem('chatHistory'); // Remove the chat history from session storage
		setChatSessionId("-1")
		sessionStorage.setItem('chatSessionId', "-1")
		setChatTitle('Start a new Conversation')
		sessionStorage.setItem('chatTitle', "Start a new Conversation")
	};

	// Save the chat history to session storage whenever it changes
	useEffect(() => {
		sessionStorage.setItem('chatHistory', JSON.stringify(chatHistory));
	}, [chatHistory]);

	const replaceEscapeSequences = (text) => {
		// Remove the initial and last quotes
		text = text.replace(/^"(.*)"$/, '$1');

		// Replace \n with actual newline characters
		text = text.replace(/\\n/g, '\n');

		// Replace \" with regular double quotes
		text = text.replace(/\\"/g, '"');

		return text;
	};

	const loadAvatar = (sender) => {
		if (sender === "Friday") {
			return (
				<Avatar
					color="gen-ai"
					iconName="gen-ai"
					tooltipText="Generative AI assistant"
				/>
			);
		} else {
			return (
				<Avatar
				/>
			);
		}
	};

	useEffect(() => {
		if (lastMessageRef.current) {
		  lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	  }, [chatHistory, lastMessageRef]);

	return (
		<div>
			<br />
			<div>
				<Header variant="h2" description="">
					<SpaceBetween direction="horizontal" size="s">
						{chatTitle}
						<Button iconName="refresh" variant="icon" onClick={clearChatHistory}>Clear Chat History</Button>
					</SpaceBetween>
				</Header>
			</div>
			<br />
			{/* <ApiGatewayRequest accessToken={accessToken}/> */}
			<div>
				{chatHistory.map((chat, index) => (
					<div key={index} ref={index === chatHistory.length - 1 ? lastMessageRef : null}>
						<SpaceBetween direction="vertical" size="s">
							<Container>
								<SpaceBetween direction="horizontal" size="s">
									{loadAvatar(chat.sender)}
									<Header
										variant="h2"
									>
										{chat.sender}
									</Header>

								</SpaceBetween>
								<Markdown>{replaceEscapeSequences(chat.message)}</Markdown>
							</Container>
						</SpaceBetween>
						<br />
					</div>
				))}
			</div>
			{handleLoadingState()}
			<br />
			<PromptInput onAction={handleSendMessage}
				onChange={({ detail }) => setMessage(detail.value)}
				value={message}
				actionButtonAriaLabel="Send message"
				actionButtonIconName="send"
				ariaLabel="Prompt input with action button"
				placeholder="Ask Friday a question"
				maxRows={30}
			/>
		</div>
	);
}

export default Chat;




