// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState } from "react";
import { Amplify, Hub, Auth } from "aws-amplify";

import "./App.css";
import Main from "./components/Main.jsx";

import {
	ContentLayout,
	Spinner,
} from '@cloudscape-design/components'

Amplify.configure({
	Auth: {
		region: "us-east-1",
		userPoolId: "us-east-1_aYvP92max",
		userPoolWebClientId: "6khcvu7qmq5n2vr0r4i8bo998q",
		identityPoolId: "us-east-1:3e868162-8b9d-483a-9169-319d2f91948c",
		oauth: {
			domain: "project-jarvis.auth.us-east-1.amazoncognito.com",
			scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
			redirectSignIn: window.location.origin,
			redirectSignOut: window.location.origin,
			responseType: "code"
		}
	}
});

const setDebug = true
const federatedIdName = "AmazonFederate";



function App() {

	const [accessToken, setAccessToken] = useState({
		jwtToken: null,
		given_name: null,
		family_name: null,
		email: null,
		exp: null,
		iat: null,
		auth_time: null,
	});
	const [isAuthenticating, setIsAuthenticating] = useState(true);

	useEffect(() => {

		async function getToken() {
			return Auth.currentSession()
				.then(session => session)
				.catch(err => console.log(err));
		}
	
		// Trigger midway Federated identity auth
		async function federateIdentity() {
			try {
				await Auth.federatedSignIn({ provider: federatedIdName }); // V5_aws-amplify
				console.log("usertoken federatedsignin" + JSON.stringify(accessToken))
				console.log("isAuthenticating federatedsignin" + isAuthenticating)
				setIsAuthenticating(false);
				console.log("isAuthenticating federatedsignin" + isAuthenticating)
			} catch (error) {
				console.error('Error during federated sign-in:', error);
				setIsAuthenticating(true);
			}
			return;
		}


		Hub.listen('auth', ({ payload: { event, data } }) => {
			console.log("isAuthenticating hub" + isAuthenticating)
			switch (event) {
				case 'signIn':
					try {
						setAccessToken({
							jwtToken: data.signInUserSession.idToken.jwtToken,
							given_name: data.signInUserSession.idToken.payload.given_name,
							family_name: data.signInUserSession.idToken.payload.family_name,
							email: data.signInUserSession.idToken.payload.email,
							exp: data.signInUserSession.idToken.payload.exp,
							iat: data.signInUserSession.idToken.payload.iat,
							auth_time: data.signInUserSession.idToken.payload.auth_time,
						});
						if (setDebug) {
							console.log('signIn: ', data);
							console.log('signIn_accessToken: ', accessToken);
						}
						console.log("usertoken dignin" + JSON.stringify(accessToken))
						console.log("isAuthenticating dignin" + isAuthenticating)
						setIsAuthenticating(false);
						console.log("isAuthenticating dignin" + isAuthenticating)
					} catch (error) {
						console.error('Error handling auth response:', error);
						setIsAuthenticating(false);
					}
					break;
				case 'cognitoHostedUI':
					if (setDebug) {
						console.log('cognitoHostedUI: ', data);
						console.log('cognitoHostedUI_accessToken: ', accessToken);
					}
					break;
				case 'signIn_failure':
					console.log('signIn_failure: ', data);
					break;
				case 'cognitoHostedUI_failure':
					console.log('cognitoHostedUI_failure: ', data);
					setIsAuthenticating(false);
					break;
				default:
					break;
			}
			setIsAuthenticating(false);
		});

		// Only when loading page: check if exists current token and not expired OR authenticate user
		if (isAuthenticating) {
			getToken().then(userToken => {
				let currtime = Math.floor(Date.now() / 1000)
				if (userToken && (userToken.idToken.payload.exp > currtime)) {
					setAccessToken({
						jwtToken: userToken.idToken.jwtToken,
						given_name: userToken.idToken.payload.given_name,
						family_name: userToken.idToken.payload.family_name,
						alias: userToken.idToken.payload.email.split("@")[0],
						email: userToken.idToken.payload.email,
						exp: userToken.idToken.payload.exp,
						iat: userToken.idToken.payload.iat,
						auth_time: userToken.idToken.payload.auth_time,
					});
					setIsAuthenticating(false);
				} else {
					federateIdentity();
				}
			})
		}

	}, [accessToken, isAuthenticating]);

	return isAuthenticating ? (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
			<Spinner size="large" />
			<p>Authenticating...</p>
			{console.log("usertoken" + JSON.stringify(accessToken))}
		</div>
	) : accessToken.jwtToken ? (

		<ContentLayout>
			{console.log("usertoken main" + JSON.stringify(accessToken))}
			{console.log("isAuthenticating main" + isAuthenticating)}
			<Main accessToken={accessToken} />
		</ContentLayout>

	) : null;
}

export default App;
